<template>
    <div>
        <v-row no-gutters v-if="$store.state.isPilot">
            <v-col>
                <p class="display-1 mt-2 mb-0">Facturen</p>
                <div class="mb-2">
                    Het is nog niet mogelijk om uw facturen te bekijken. In een toekomstige versie van de website kan dit wel.
                </div>
            </v-col>
        </v-row>
        <div v-if="!$store.state.isPilot">
            <v-row no-gutters>
                <v-col>
                    <p class="display-1 mb-2">Facturen</p>
                </v-col>
            </v-row>

            <v-divider color="white"></v-divider>

            <v-row >
                <v-col v-for="(invoice, index) in invoices" :key="index">
                    <v-list-item class="px-0 pt-1">
                        <v-list-item-avatar width="60" height="60">
                            <div class="pa-7 rounded-circle d-inline-block initials-avatar text-uppercase font-weight-black">{{getInitials(invoice.orderNumber)}}</div>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                <span class="title font-weight-light">Factuurnummer: {{invoice.orderNumber}}</span>
                            </v-list-item-title>
                            <div class="py-0">
                                Bedrag: {{getCurrency(invoice.totalPrice)}} | Betaald: {{formatDate(getDateOnly(invoice.paid))}}
                            </div>
                        </v-list-item-content>
                        <img v-if="invoice.url != null" class="download-icon" :src="downloadIcon" alt="Download" />
                    </v-list-item>
                </v-col>
            </v-row>
        </div>
        
    </div>
</template>

<script>
import FormatMixin from "@/mixins/format.mixin";

export default {
    name: 'Invoices',
    props: {
        invoices: Array
    },
    components: 
    {
    },
    mixins: [FormatMixin],
    data: () => ({
        amount: 120.00,
        paidOn: "28-08-2018",
        downloadIcon: require('@/assets/icons/download.svg'),
    }),
    methods: {
        getInitials(title)
        {
            const [, , number] = title.split('-');
            return number;
        }
    }
}
</script>
<style>
.download-icon 
{
    width: 20px;
    height: 20px;
    margin: 3px;
    cursor: pointer;
}

.initials-avatar
{
    background: white;
    color: #181717;
    font-size: 22px;
}
</style>