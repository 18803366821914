<template>
    <BannerTemplate :bannerSource="bannerImg">
        <LoadingIcon :isLoading="isLoading" />
        <div v-if="!isLoading" class="px-2 mt-n5 aankomende-roadtrip" :class="{'inAWeek' : isDateWithinAWeek, 'before' : !isDateWithinAWeek }">
            <v-container>
                <v-row no-gutters>
                    <v-col cols="8" class="d-flex">
                        <span class="display-1">{{roadtrip.roadtripName}}</span>
                    </v-col>
                    <v-col cols="4" class="text-end pt-2">{{formatDayMonthYear(roadtrip.date)}}</v-col>
                    <v-col cols="12" class="mt-2">Op {{formatDayMonth(roadtrip.date)}} rijdt u mee met de {{roadtrip.roadtripName}}. Op deze pagina vindt u alle informatie over uw deelname.</v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" class="display-1 mt-3 mb-0">
                        Ticket en route
                    </v-col>
                    <v-col class="" v-if="!isDateWithinAWeek">U kunt uw ticket en de route hier vanaf {{formatDayMonth(roadtrip.dateWeekPrior)}} downloaden. Tot dan is het namelijk mogelijk om uw deelname te wijzigen.</v-col>
                    <v-col cols="12" v-if="isDateWithinAWeek">
                        Met onderstaande knoppen kunt u uw ticket en de route downloaden. De ticket geeft u toegang tot het evenement en de route is vanzelfsprekend de route die we met z'n allen gaan rijden. Wij raden het gebruik van OsmAnd Maps aan om de route in te laden (zie de instructies in de mail).

                        <v-btn color="primary" block class="mt-1 mb-3">
                        Download ticket
                        </v-btn>

                        <v-btn color="primary" block>
                        Download route
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" class="display-1 mt-3 mb-0">
                        Details
                    </v-col>
                    <v-col class="" v-if="!isDateWithinAWeek" >
                        Hieronder staan de gegevens van uw aanmelding die bij ons bekend zijn. Klik op één van de blauwe knoppen om uw bestelling te wijzigen.
                    </v-col>
                    <v-col cols="12" v-if="isDateWithinAWeek">
                        <div>
                            Hieronder staan de gegevens van uw aanmelding die bij ons bekend zijn. Het is niet meer mogelijk om die te wijzigen. Klopt er iets niet? Stuur een e-mail naar
                            <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a
                                    style="color:#76baff"
                                    target="_blank"
                                    href="mailto:service@roadpursuit.com"
                                    @click.stop
                                    v-on="on">
                                    service@roadpursuit.com
                                </a>
                                </template>
                                Opent uw mail app
                            </v-tooltip>
                            .
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <horizontal-scroll class="horizontal-scroll mt-3">
                        <div class="outer">
                            <div class="inner-content">
                                <v-card
                                    :disabled="isDateWithinAWeek"
                                    class="primary rounded-lg"
                                    height="80" width="175"
                                    @click="goToUpComingTripWijzigen(uniqueName)">
                                    <v-card-text class="pb-1 pt-2 px-3 detail-header font-weight-medium">
                                        Voertuig:
                                    </v-card-text>
                                    <v-spacer />
                                    <v-card-actions>
                                        <div class="voertuig-subheader px-1 text-start">
                                            <span class="font-weight-light">
                                                {{roadtrip.details.vehicleName}}
                                            </span>
                                        </div>
                                    </v-card-actions>
                                </v-card>
                            </div>
                            <div class="inner-content">
                                <v-card
                                    :disabled="isDateWithinAWeek"
                                    class="primary rounded-lg"
                                    height="80" width="175"
                                    @click="goToUpComingTripWijzigen(uniqueName)">
                                    <v-card-text class="pb-1 pt-2 px-3 detail-header font-weight-medium">
                                        Personen:
                                    </v-card-text>
                                    <v-card-actions>
                                        <div class="detail-subheader pb-2 px-1 mt-n2 text-start">
                                            <span class="font-weight-light">
                                                {{roadtrip.details.totalPersons}}
                                            </span>
                                        </div>
                                    </v-card-actions>
                                </v-card>
                            </div>
                            <div class="inner-content" v-if="roadtrip.details.productOffersLunch">
                                <v-card
                                    :disabled="isDateWithinAWeek"
                                    class="primary rounded-lg"
                                    height="80" width="175"
                                    @click="goToUpComingTripWijzigen(uniqueName)">
                                    <v-card-text class="pb-1 pt-2 px-3 detail-header font-weight-medium">
                                        Lunch:
                                    </v-card-text>
                                    <v-card-actions>
                                        <div class="detail-subheader pb-2 px-1 mt-n2 text-start">
                                            <span class="font-weight-light">
                                                {{roadtrip.details.totalLunch}}
                                            </span>
                                        </div>
                                    </v-card-actions>
                                </v-card>
                            </div>
                             <div class="inner-content" v-if="roadtrip.details.productOffersDinner">
                                <v-card
                                    :disabled="isDateWithinAWeek"
                                    class="primary rounded-lg"
                                    height="80" width="175"
                                    @click="goToUpComingTripWijzigen(uniqueName)">
                                    <v-card-text class="pb-1 pt-2 px-3 detail-header font-weight-medium">
                                        Diner:
                                    </v-card-text>
                                    <v-card-actions>
                                        <div class="detail-subheader pb-2 px-1 mt-n2 text-start">
                                            <span class="font-weight-light">
                                                {{roadtrip.details.totalDinner}}
                                            </span>
                                        </div>
                                    </v-card-actions>
                                </v-card>
                            </div>
                        </div>
                    </horizontal-scroll>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" class="display-1 mt-3">
                        Planning van {{formatDayMonth(roadtrip.date)}}
                    </v-col>
                    <v-col class="" v-if="!isDateWithinAWeek">
                        Hieronder ziet u de planning van de dag. De exacte details maken wij één week voorafgaand aan het evenement bekend.
                    </v-col>
                    <v-col cols="12" v-if="isDateWithinAWeek">
                        Hieronder ziet u de planning van de dag. Klik op de lichtgekleurde blokken om de locatie in Google Maps te openen.
                    </v-col>
                </v-row>
                <EventSlotCalendar :events="events" :eventDate="roadtrip.date" />
                <Invoices :invoices="roadtrip.invoices"/>
            </v-container>
        </div>
    </BannerTemplate>
</template>

<script>
import { mapState } from 'vuex';
import LoadingIcon from '@/components/common/LoadingIcon';
import BannerTemplate from '@/components/common/BannerTemplate';
import HorizontalScroll from 'vue-horizontal-scroll'
import Invoices from '@/components/dashboard/Invoices';
import EventSlotCalendar from '@/components/common/EventSlotCalendar';
import DataMixin from "@/mixins/data.mixin";
import FormatMixin from "@/mixins/format.mixin";
import RouteMixin from "@/mixins/route.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'

export default {
    name: 'AankomendeRoadtrip',
    components: 
    {
        HorizontalScroll,
        BannerTemplate,
        EventSlotCalendar,
        Invoices,
        LoadingIcon
    },
    mixins: [DataMixin, FormatMixin, RouteMixin, MenuHelperMixin, MetaTagsMixin],
    data: () => ({
        bannerImg: require('@/assets/album/Eifel Tour/eifel-tour-01.jpg'),
        isLoading: true,
        roadtrip: {
            itinerary: {
                details: []
            },
            details: {},
            invoices: []
        },
        events: [],
        isDateWithinAWeek: false,
        roadtripId: '',
        uniqueName: ''
    }),
    computed: {
        ...mapState('authorizedModule', ['userId']),
    },
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
        this.uniqueName = this.$route.params.trip;
        this.getRoadtripId();
    },
    metaInfo() {
        return {
        title: this.roadtrip.roadtripName ? `| ${this.roadtrip.roadtripName}` : `| Aankomend`,
        meta: [
            this.getKeywords(`${this.roadtrip.roadtripName}, roadtrip`),
            this.getMetaDescription(`${this.roadtrip.roadtripName}`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`${this.roadtrip.roadtripName}`, `${this.roadtrip.roadtripName}`, `${window.location.origin}${this.bannerImg}`, `${this.roadtrip.roadtripName}`),
        ]
        };
    },
    methods: {
        getRoadtripId()
        {
            this.$store.dispatch('roadtripModule/getRoadtripId', this.uniqueName)
                .then(data => {
                    this.roadtripId = data;
                    this.getUpcomingRoadtrip();
            })
            .catch(() => { 
                this.$store.commit('dialog/showDialog', `Unable to retrieve roadtrip.`);
                this.isLoading = false; 
            });
        },
        getUpcomingRoadtrip()
        {
            const request = {
                accountId: this.userId,
                roadtripId: this.roadtripId
            }

            this.isLoading = true;
            this.$store.dispatch('roadtripModule/getUpcomingRoadtripInformation', request)
                .then(data => {

                this.roadtrip = data;
                this.roadtrip.date = data.itinerary.details.length > 0 
                                    ? this.getDateOnly(data.itinerary.details[0].arrivalTime) : null;

                this.events = [];
                data.itinerary.details.forEach(event => {
                    this.events.push({
                        name: event.title,
                        start: `${this.getDateOnly(event.arrivalTime)} ${this.getTimeOnly(event.arrivalTime)}`,
                        end: `${this.getDateOnly(event.departureTime)} ${this.getTimeOnly(event.departureTime)}`,
                        link: event.googleMapsURL
                    })
                });

                let now = new Date(this.getToday())
                let roadtripDate = new Date(this.roadtrip.date);
                this.roadtrip.dateWeekPrior = this.addDays(this.roadtrip.date, -7);
                this.isDateWithinAWeek = now >= this.roadtrip.dateWeekPrior && now <= roadtripDate;
                this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        },
    }
}
</script>
<style>

.aankomende-roadtrip .horizontal-scroll {
    display: flex;
    width: 100%;
    height: 80px;
}

.aankomende-roadtrip .outer {
    display: flex;
    flex: 1;
    width: auto;
    height: 100%;
    flex-flow: row nowrap;
}

.aankomende-roadtrip .inner-content {
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 175px;
    color: white;
    border-radius: 5px;
}

.aankomende-roadtrip .inner-content .detail-header,
.aankomende-roadtrip .inner-content .detail-subheader 
{
    color: white !important;
}

.aankomende-roadtrip .inner-content:not(:first-of-type) {
    margin-left: 10px;
}

.aankomende-roadtrip.before .event-slot .theme--dark.v-calendar-events .v-event-timed.darken-3 {
    cursor: initial;
}

.aankomende-roadtrip.inAWeek .event-slot .theme--dark.v-calendar-events .v-event-timed.primary.darken-3 {
    cursor: initial;
}

.voertuig-subheader
{
    line-height: 18px;
    font-size: 16px;
}
</style>